import React from 'react';
import { useDispatch } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi'
import { CgCornerDownRight } from 'react-icons/cg'
import { deleteCommentReply } from '../../../../actions/posts';
import styled from 'styled-components';
import moment from 'moment';
import LikesDislikes from '../../../LikesDislikes/LikesDislikes';

const ReplyContainer = styled.div`
  font-size: 12px;
  border-bottom: 1px solid #cac7c7;
  border-bottom-style: dashed;
  padding: 16px 0 12px 0;
`
const ReplyDetails = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    color: grey;
  }
`

const ReplyCreator = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`

const ReplyDate = styled.div`
  color: grey;
  margin-left: 8px;
`

const DeleteButton = styled.button`
  padding: 2px;
  margin-left: 8px;
  border: none;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    color: red;
    font-size: 12px;
    stroke-width: 2.1;
  }

  svg:hover {
    stroke-width: 2.4;
  }
`

const ReplyText = styled.div`
  padding: 5px 10px;
  padding-left: 22px;
`

const Reply = ({ postId, commentId, reply }) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();

  const handleReplyDelete = (e) => {
    e.preventDefault();
    dispatch(deleteCommentReply(postId, commentId, reply._id));
  };

  const isCreator = () => {
    return (user?.result?.sub === reply.userId) || (user?.result?._id === reply.userId);
  }

  const getUserId = () => {
    return user?.result?.name ? (user?.result?._id || user?.result?.sub) : '';
  }

  return (
    <ReplyContainer key={reply._id} className="comment-reply">
      <ReplyDetails>
        <CgCornerDownRight />
        <ReplyCreator>{reply.creator}</ReplyCreator>|
        <ReplyDate>{moment(reply.createdAt).fromNow()}</ReplyDate>
        { isCreator() && (
          <DeleteButton onClick={handleReplyDelete}>
            <FiTrash2 />
          </DeleteButton> 
        )}
      </ReplyDetails>
      <ReplyText>{reply.text}</ReplyText>
      <LikesDislikes reply replyId={reply._id} userId={getUserId()} />
    </ReplyContainer>
  )
}

export default Reply;