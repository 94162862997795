export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_POST = 'FETCH_POST';
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const OPENPOSTMODAL = 'OPENPOSTMODAL';
export const CLOSEPOSTMODAL = 'CLOSEPOSTMODAL';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

// COMMENTS
export const COMMENT = 'COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const START_LOADING_COMMENTS = 'START_LOADING_COMMENTS';
export const END_LOADING_COMMENTS = 'END_LOADING_COMMENTS';
export const CREATE_REPLY = 'CREATE_REPLY';
export const DELETE_REPLY = 'DELETE_REPLY';