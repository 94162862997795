import { OPENPOSTMODAL, CLOSEPOSTMODAL } from "../constants/actionTypes";

const modalReducer = (state = { openPostModal: false }, action) => {
  switch (action.type) {
    case OPENPOSTMODAL:
      return { ...state, openPostModal: true };
    case CLOSEPOSTMODAL:
      return { ...state, openPostModal: false };
    default:
      return state; 
  }
}

export default modalReducer;