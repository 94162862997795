import React, { useEffect, useState } from 'react';
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import { RiThumbUpFill, RiThumbDownFill } from 'react-icons/ri';
import * as api from '../../api';
import styled from 'styled-components';

const ReactionContainer = styled.div`
  display: flex;
  align-items: center;
`

const Button = styled.button`
  border: none;
  background: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:disabled {
    color: grey;
    cursor: unset;
  }
`

const LikeButton = styled(Button)`
  color: #43a0e5;
`

const DislikeButton = styled(Button)`
  color: #f14646;
`

const ButtonText = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
`

const LikesDislikes = ({ post, comment, reply, postId, commentId, replyId, userId }) => {
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [currLiked, setCurrLiked] = useState(false);
  const [currDisliked, setCurrDisliked] = useState(false);

  let variable = {};
  if (post) {
    variable = { postId, userId };
  } else if (comment) {
    variable = { commentId, userId };
  } else if (reply) {
    variable = { replyId, userId };
  }

  useEffect(() => {
    async function fetchLikesDislikesData() {
      try {
        const likes = await api.getLikes(variable);
        setLikes(likes.data.length);
        likes.data.map((like) => {
          if (like.userId === userId) setCurrLiked(true);
          return like;
        })

        const dislikes = await api.getDislikes(variable);
        setDislikes(dislikes.data.length);
        dislikes.data.map((dislike) => {
          if (dislike.userId === userId) setCurrDisliked(true);
        })

      } catch (error) {
        console.log(error.message);
      }
    }

    fetchLikesDislikesData();
  }, []);

  const handleLike = async () => {
    try {
      if (currLiked) {
        await api.unLike(variable);
        setLikes(likes-1);
        setCurrLiked(false);
      } else {
        await api.upLike(variable);
        setLikes(likes+1);
        setCurrLiked(true);

        if (currDisliked) {
          setCurrDisliked(false);
          setDislikes(dislikes-1);
        }
      }
    } catch (error) {

    }
  }

  const handleDislike = async () => {
    try {
      if (currDisliked) {
        await api.unDislike(variable);
        setDislikes(dislikes-1);
        setCurrDisliked(false);
      } else {
        await api.upDislike(variable);
        setDislikes(dislikes+1);
        setCurrDisliked(true);

        if (currLiked) {
          setCurrLiked(false);
          setLikes(likes-1);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const disableButton = () => {
    return userId == '' ? true : false;
  }

  return (
    <ReactionContainer>
      <LikeButton onClick={handleLike} disabled={disableButton()}>
        { currLiked ? <RiThumbUpFill /> : <FiThumbsUp /> }
        <ButtonText>{likes}</ButtonText>
      </LikeButton>
      <DislikeButton onClick={handleDislike} disabled={disableButton()}>
        { currDisliked ? <RiThumbDownFill /> : <FiThumbsDown /> }
        <ButtonText>{dislikes}</ButtonText>
      </DislikeButton>
    </ReactionContainer>
  )
}

export default LikesDislikes;