import { combineReducers } from "redux";
import posts from './posts';
import auth from './auth';
import modals from './modals';

// combine all the indv reducers that we have
export default combineReducers({
  posts,
  auth,
  modals,
})