import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPostComment, getPostComments } from '../../actions/posts';
import styled from 'styled-components';
import LoadingSpinner from '../Loader/LoadingSpinner';
import Comment from './Comment/Comment';

const CommentSectionContainer = styled.div`
  margin-bottom: 100px;
`

const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  background: #798825;
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 20px 12px 16px 12px;
`

const FormInputLabel = styled.label`
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
`

const FormInputWrapper = styled.div`
  width: 100%;
  display: flex;
`

const FormTextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande';
`

const FormSubmitButton = styled.button`
  font-size: 10px;
  color: white;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 5px;
  background: black;
  opacity: 60%;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    opacity: 90%;
  }
`

const CommentsCount = styled.div`
  margin-bottom: 10px;
`

const CommentsWrapper = styled.div`
  border-top: 1px solid grey;
`

const CommentsSection = ({ postId }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const { postComments, isLoadingComments } = useSelector((state) => state.posts);
  const [ comment, setComment ] = useState('');

  useEffect(() => {
    dispatch(getPostComments(postId));
  }, []);

  const handleChange = (e) => {
    const text = e.target.value;
    setComment(text);
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createPostComment(postId, { comment, creator: user.result.name }));
    setComment('');
  }

  const disableFormSubmit = () => {
    return user?.result?.name ? false : true;
  }

  const placeholderText = () => {
    return disableFormSubmit() ? 'Please log in to comment' : 'Enter text';
  }

  if (!postComments) return null;
  
  if (isLoadingComments) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <CommentSectionContainer>
      <CommentForm onSubmit={handleSubmit}>
        <FormInputLabel htmlFor="comment-text">Enter a comment</FormInputLabel>
        <FormInputWrapper>
          <FormTextArea id="comment-text" placeholder={placeholderText()} rows="5" value={comment} onChange={handleChange}  />
          <FormSubmitButton disabled={disableFormSubmit()}>SUBMIT</FormSubmitButton>
        </FormInputWrapper>
      </CommentForm>
      <CommentsCount>{postComments.length} Comments</CommentsCount>
      <CommentsWrapper>
        { postComments.map((comment) => (
          <Comment key={comment._id} comment={comment} postId={postId} />
        ))}
      </CommentsWrapper>
    </CommentSectionContainer>
  )
}

export default CommentsSection;