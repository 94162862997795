import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FiClock, FiTrash2 } from 'react-icons/fi';
import { FaRegComment } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { createCommentReply, deletePostComment } from '../../../actions/posts';
import Reply from './Reply/Reply';
import LikesDislikes from '../../LikesDislikes/LikesDislikes';

const CommentContainer = styled.div`
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid lightgrey;
`

const CommentDetails = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  margin-bottom: 10px;
`

const CommentCreator = styled.p`
  margin: 0;
  margin-right: 10px;
`

const CommentDateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`
const CommentDate = styled.div`
  margin-left: 10px;
`
const CommentText = styled.div`
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 10px;
`

const CommentActions = styled.div`
  display: flex;
`

const DeleteButton = styled.button`
  padding: 4px 5px;
  font-size: 12px;
  background: grey;
  opacity: 80%;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    transition: 0.2s;
    background: black;
    color: white;
  }
`

const ShowRepliesButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  cursor: pointer;
  border: none;

  &:hover {
    color: grey;
  }
`
const RepliesCount = styled.p`
  margin: 0;
  margin-left: 5px;
`

const ReplyForm = styled.form`
  display: flex;
  margin-top: 24px;
  margin-bottom: 10px;
`

const RepliesContainer = styled.div`
  background: white;

  .comment-reply:last-child {
    border: none;
  }
`

const ReplyField = styled.textarea`
  flex: 1;
  padding: 12px;
  padding-left: 14px;
  font-size: 13px;
  font-family: 'Lucida Sans','Lucida Sans Regular','Lucida Grande';
`

const ReplyButton = styled.button`
  padding: 0px 20px;
  margin-left: 10px;
  color: white;
  font-size: 12px;
  letter-spacing: 0.7px;
  background: black;
  border: none;
  border-radius: 10px;
  border: 1px solid grey;
  cursor: pointer;
`

const Comment = ({ comment, postId }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [ reply, setReply ] = useState('');
  const [ showReplies, setShowReplies ] = useState(false);
  const [ replies, setReplies ] = useState(comment.replies || []); // passed down from store
  const postComments = useSelector((state) => state.posts.postComments);

  useEffect(() => {
    setReplies(comment.replies);
  }, [postComments]);

  const handleCommentDelete = (e, commentId) => {
    e.preventDefault();
    dispatch(deletePostComment(postId, commentId));
  }

  const handleReply = async (e, commentId) => {
    e.preventDefault();
    let newReply = await dispatch(createCommentReply(postId, commentId, { reply, creator: user.result.name }));
    setReplies((prev) => [...prev, newReply]);
    setReply('');
  }

  const handleShowReplies = () => {
    setShowReplies((prev) => !prev);
  }

  const isCreator = () => {
    return (user?.result?.sub === comment.userId) || (user?.result?._id === comment.userId);
  }

  const disableReply = () => {
    return user?.result?.name ? false : true;
  }

  const getUserId = () => {
    return user?.result?.name ? (user?.result?._id || user?.result?.sub) : '';
  }

  const replyPlaceholderText = () => {
    return user?.result?.name ? 'Add a reply' : 'Please log in to reply';
  }

  return (
    <CommentContainer>
      <CommentDetails>
        <CommentCreator>{comment.creator}</CommentCreator>|
        <CommentDateWrapper>
          <FiClock />
          <CommentDate>{moment(comment.createdAt).fromNow()}</CommentDate>
        </CommentDateWrapper>
      </CommentDetails>
      <CommentText>{comment.text}</CommentText>
      <CommentActions>
        { isCreator() && <DeleteButton onClick={(e) => handleCommentDelete(e, comment._id)}><FiTrash2 /></DeleteButton> }
        <ShowRepliesButton onClick={handleShowReplies}>
          <FaRegComment />
          <RepliesCount>{replies.length}</RepliesCount>
        </ShowRepliesButton>
        <LikesDislikes comment commentId={comment._id} userId={getUserId()} />
      </CommentActions>
      { showReplies && (
        <>
        <ReplyForm onSubmit={(e) => handleReply(e, comment._id)}>
          <ReplyField placeholder={replyPlaceholderText()} value={reply} rows="3" onChange={(e) => setReply(e.target.value)} />
          <ReplyButton disabled={disableReply()} type="submit">POST</ReplyButton>
        </ReplyForm>
        <RepliesContainer>
          { replies.map((reply) => (
              <Reply key={reply._id} postId={postId} commentId={comment._id} reply={reply} />
            ))
          }
        </RepliesContainer>
        </>
      ) }
    </CommentContainer>
  )
}

export default Comment;