import React from 'react';
import styled from 'styled-components';
import { VscEyeClosed, VscEye } from 'react-icons/vsc'

const InputWrapper = styled.label`
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  position: relative;
`
const InputLabel = styled.div`
  margin-right: 10px;
  line-height: 22px;
  flex: 0.2;
`

const InputField = styled.input`
  flex: 0.8;
  padding: 6px;
  line-height: 22px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
`

const PasswordButton = styled.button`
  width: fit-content;
  padding: 4px;
  font-size: 10px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  background: white;
  cursor: pointer;

  position: absolute;
  top: 7px;
  right: 7px;
`

const TextField = ({ label, name, type, value, handleChange, autoFocus, showPassword, handleShowPassword }) => {
  return (
    <InputWrapper>
      <InputLabel>{label}</InputLabel>
      <InputField name={name} type={type} value={value} onChange={(e) => handleChange(e)} autoFocus={autoFocus} required />
      { name === 'password'  && (
        <PasswordButton onClick={handleShowPassword}>
          { showPassword ? <VscEye /> : <VscEyeClosed /> }
        </PasswordButton> 
      )}
    </InputWrapper>
  )
}

export default TextField;