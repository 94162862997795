import axios from 'axios';

const API = axios.create({ baseURL: 'https://charlies-pin.herokuapp.com/' });
// Won't have to specify the base url, will create the same axios request

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
  }

  return req; // need to return req to make all requests below
})
// Will happen on every request, in order to send bearer token to backend (auth middleware)
// The middleware will check req header for this token to verify token validity and decode user info.
// If token exists, it means that the req is coming from a signed in user

export const fetchPosts = () => API.get('/posts');
export const createPost = (newPost) => API.post(`/posts`, newPost);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const getPostComments = (postId) => API.get(`/posts/${postId}/comments`);
export const createPostComment = (postId, newComment) => API.post(`/posts/${postId}/commentPost`, newComment);
export const deletePostcomment = (postId, commentId) => API.delete(`/posts/${postId}/comments/${commentId}`);
export const createCommentReply = (postId, commentId, newReply) => API.post(`/posts/${postId}/comments/${commentId}/replies/new`, newReply);
export const deleteCommentReply = (postId, commentId, replyId) => API.delete(`/posts/${postId}/comments/${commentId}/replies/${replyId}`);

export const getLikes = (likesData) => API.post('/likes/getLikes', likesData);
export const getDislikes = (dislikesData) => API.post('/likes/getDislikes', dislikesData);
export const upLike = (data) => API.post('/likes/upLike', data);
export const upDislike = (data) => API.post('/likes/upDislike', data);
export const unLike = (data) => API.post('/likes/unlike', data);
export const unDislike = (data) => API.post('/likes/unDislike', data);

export const signup = (formData) => API.post('/users/signup', formData);
export const signin = (formData) => API.post('/users/signin', formData);