import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import decode from 'jwt-decode';

const AppHeader = styled.nav`
  background: white;
  width: 100%;
  padding: 10px;
  position: fixed;
  top: 0px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  z-index: 1000;
`

const UserActions = styled.div`
  display: flex;
  align-items: center;
`

const UserName = styled.p`
  margin: 0;
`

const LogoutButton = styled.button`
  color: white;
  background: black;
  margin-left: 10px;
  padding: 3px 10px;
  
  border: none;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.7px;
  cursor: pointer;

  &:hover {
    background: grey;
    transition: 0.3s;
  }
`;

const CreatePostButton = styled(LogoutButton)`
  background: white;
  color: black;
  border: 1px solid grey;
`

const PinLogo = styled.button`
  color: white;
  padding: 5px;
  padding-left: 7px;
  background: black;

  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
  border: none;
  border-radius: 50%;
  letter-spacing: 1.5px;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    background: grey;
  }
`

const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const token =  user?.token;

    if (token) {
      const decodedToken = decode(token);
      // value in ms
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout();
      }
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
    // trigger this whenever url location changes
  }, [location])

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/');
    setUser(null);
  }

  const handleCreatePost = () => {
    dispatch({ type: 'OPENPOSTMODAL' });
  }

  return (
    <AppHeader>
      <CreatePostButton onClick={handleCreatePost}>CREATE A POST</CreatePostButton>
      <Link to="/">
        <PinLogo>PiN</PinLogo>
      </Link>
      {  user ? ( 
        <UserActions>
          <UserName>{user.result.name}</UserName>
          <LogoutButton onClick={logout}>LOGOUT</LogoutButton>
        </UserActions>
      ) : (
        <Link to="auth">
          <LogoutButton>SIGN IN</LogoutButton>
        </Link>
      )}
    </AppHeader>
  )
};

export default Navbar;