import { FETCH_ALL, CREATE, UPDATE, DELETE, FETCH_POST, START_LOADING, END_LOADING, COMMENT, DELETE_COMMENT, FETCH_COMMENTS, START_LOADING_COMMENTS, END_LOADING_COMMENTS, CREATE_REPLY, DELETE_REPLY } from "../constants/actionTypes";

const reducer = (state = { isLoading: true, isLoadingComments: true, posts: [], post: {}, postComments: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_ALL:
      return { ...state, posts: action.payload };
    case CREATE:
      return { ...state, posts: [...state.posts, action.payload] };
    case UPDATE:
      const updatedPostId = action.payload._id
      return { ...state, posts: state.posts.map((post) => post._id === updatedPostId ? action.payload : post) };
    case DELETE:
      const deletedPostId = action.payload;
      return { ...state, posts: state.posts.filter((post) => post._id !== deletedPostId) };
    case FETCH_POST:
      return { ...state, post: action.payload };
    case FETCH_COMMENTS:
        return { ...state, postComments: action.payload };
    case COMMENT:
      return { ...state, postComments: [...state.postComments, action.payload] };
    case DELETE_COMMENT:
      const deletedCommentId = action.payload.commentId;
      const newComments = state.postComments.filter((comment) => comment._id !== deletedCommentId);
      return { ...state, postComments: newComments };
    case START_LOADING_COMMENTS:
      return { ...state, isLoadingComments: true };
    case END_LOADING_COMMENTS:
      return { ...state, isLoadingComments: false };
    case CREATE_REPLY:
      const { commentId, reply } = action.payload;
      const updatedComments = state.postComments.map((comment) => {
        if (comment._id === commentId) {
          comment.replies = [...comment.replies, reply];
        }
        return comment;
      })
      return { ...state, postComments: updatedComments };
    case DELETE_REPLY:
      const { relatedCommentId, deletedReplyId } = action.payload;
      const commentsWithUpdatedReplies = state.postComments.map((comment) => {
        if (comment._id === relatedCommentId) {
          comment.replies = comment.replies.filter((reply) => reply._id !== deletedReplyId );
        }
        return comment;
      })
      return { ...state, postComments: commentsWithUpdatedReplies };
    default:
      return state;
  }
}

export default reducer;