import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { signup, signin } from '../../actions/auth';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';
import TextField from './TextField';

const Main = styled.main`
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 50px;
  padding-left: 230px;
  box-sizing: border-box;
`

const FormContainer = styled.div`
  width: 450px;
  height: fit-content;
  margin: 10px;
  padding: 20px 40px;
  border-radius: 10px;
  border: 1px solid #d2d0d0;
  box-shadow: 2px 1px 10px #efefef;

  display: flex;
  flex-direction: column;
  align-items: center;
`
const Form = styled.form`
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SubmitButton = styled.button`
  width: 100%;
  padding: 8px 20px;
  margin-top: 14px;
  margin-bottom: 20px;
  background: black;
  color: white;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 1px;
  border: none;
  border: 1px solid grey;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    background: grey;
  }
`

const SwitchButton = styled.button`
  margin-top: 10px;
  background: white;
  border: none;
  font-size: 12px;
  letter-spacing: 0.1px;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    color: #666767;
  }
`


const Auth = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', username: '', password: '', confirmPassword: '' });
  const [isSignUp, setSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // prevent page refresh

    if (isSignUp) {
      dispatch(signup(formData, history))
    } else {
      dispatch(signin(formData, history));
    }

    clearForm();
  }

  const switchMode = () => {
    setSignUp((prev) => !prev);
    setShowPassword(false);
    clearForm();
  }

  const clearForm = () => {
    setFormData({ firstName: '', lastName: '', email: '', username: '', password: '', confirmPassword: '' });
  }

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  }

  const googleSuccess = async (res) => {
    const { email, name, sub } = jwt_decode(res.credential);
    const userData = { email, name, sub };
    dispatch({ type: 'AUTH', data: { result: userData, token: res.credential }});
    history.push('/');
  }

  const googleFailure = (error) => {
    console.log(error);
    console.log("Google sign in was unsuccessful. Try again later.")
  }

  return (
    <Main>
      <FormContainer>
        <div>{ isSignUp ? 'SIGN UP': 'SIGN IN'}</div>
        <Form onSubmit={handleSubmit}>
          { isSignUp && (
            <>
              <TextField label="First Name" name="firstName" type="text" value={formData.firstName} handleChange={handleChange} autoFocus={true} />
              <TextField label="Last Name" name="lastName" type="text" value={formData.lastName}  handleChange={handleChange} autoFocus={true} />
            </>
          )}
          <TextField label="Email" name="email" type="email" value={formData.email}  handleChange={handleChange} autoFocus={true} />
          <TextField label="Password" name="password" type={showPassword ? "text" : "password"} value={formData.password}  handleChange={handleChange} autoFocus={true} showPassword={showPassword} handleShowPassword={handleShowPassword} />
          { isSignUp && <TextField label="Confirm Password" name="confirmPassword" type="password" value={formData.confirmPassword}  handleChange={handleChange} autoFocus={true} /> }
          <SubmitButton type="submit">{ isSignUp ? 'SIGN UP' : 'SIGN IN' }</SubmitButton>
          <GoogleLogin
            onSuccess={googleSuccess}
            onFailure={googleFailure}
          />
        </Form>
        <SwitchButton onClick={switchMode}>
          {isSignUp ? 'Already have an account? Sign in': "Don't have an account? Sign up"}
        </SwitchButton>
      </FormContainer>
    </Main>
  )
}

export default Auth;
