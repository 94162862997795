import React from 'react';
import styled from 'styled-components';
import { useSelector  } from 'react-redux';
import Post from './Post/Post';

const PostsContainer = styled.div`
  flex: 0.8;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`
const PostsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Posts = ({ setCurrentPostId }) => {
  const { posts } = useSelector((state) => state.posts);

  return (
    <PostsContainer>
      <PostsBox>
        {posts.length ? posts.map((post) => (
          <Post key={post._id} post={post} setCurrentPostId={setCurrentPostId} />
        )) : 
        <div>Loading...</div>
        }
      </PostsBox>
    </PostsContainer>
  )
}

export default Posts;