import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiClock } from 'react-icons/fi';
import { getPost } from '../../actions/posts';
import styled from 'styled-components';
import moment from 'moment';
import LoadingSpinner from '../Loader/LoadingSpinner';
import CommentsSection from '../Comments/CommentsSection';
import LikesDislikes from '../LikesDislikes/LikesDislikes';

const Main = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-left: 230px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`
const PostDetailsWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
`

const PostMetadata = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 20px 0px 15px 0px;
`

const PostCreator = styled.div`
  margin-right: 10px;
  font-weight: 600;
`

const PostDate = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`

const PostDateDetail = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
  color: darkgrey;
`

const PostTitle = styled.div`
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
`

const PostMessage = styled.div`
  margin-bottom: 20px;
`

const PostImage = styled.img`
  width: 600px;
  height: 400px;
  border-radius: 10px;
  margin-bottom: 60px;
`

const PostDetails = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const { id } = useParams();
 
  const getUserId = () => {
    return user?.result?.name ? (user?.result?._id || user?.result?.sub) : '';
  }

  useEffect(() => {
    dispatch(getPost(id));
  }, [id]);

  if (!post) return null;

  if (isLoading) {
    return (
      <Main>
        <LoadingSpinner />
      </Main>
    )
  }

  return (
    <Main>
      <PostDetailsWrapper>
        <PostMetadata>
          <PostCreator>{post.name}</PostCreator> | 
          <PostDate>
            <FiClock />
            <PostDateDetail>{moment(post.createdAt).fromNow()}</PostDateDetail>
          </PostDate>
        </PostMetadata>
        <PostTitle>{post.title}</PostTitle>
        <PostMessage>{post.message}</PostMessage>
        <PostImage src={post.selectedFile} />
        <LikesDislikes post postId={post._id} userId={getUserId()} />
        <CommentsSection postId={id} />
      </PostDetailsWrapper>
    </Main>
  )
}

export default PostDetails;