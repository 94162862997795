import React from 'react';
import styled from 'styled-components';

const SideNav = styled.nav`
  width: 200px;
  height: calc(98% - 50px);
  margin-top: 50px;
  margin-left: 20px;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: lightgrey;
  opacity: 70%;
`

const Sidebar = () => {
  return (
    <SideNav>
    </SideNav>
  )
}

export default Sidebar;