import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts } from '../../actions/posts';

import Posts from '../Posts/Posts';
import Form from '../Form/Form';

const Main = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-left: 230px;
  box-sizing: border-box;
`

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(57,57,57,0.2);
`

const CreatePostModal = styled.div`
  background: white;
  width: 50%;
  padding: 10px;
  max-width: 550px;
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(57,57,57,0.1);
`

const Home = () => {
  const dispatch = useDispatch();
  const openPostModal = useSelector((state) => state.modals.openPostModal);
  const [currentPostId, setCurrentPostId] = useState(null);

  useEffect(() => {
    dispatch(getPosts());
  }, [currentPostId, dispatch])

  return (
    <Main>
      <Posts setCurrentPostId={setCurrentPostId} />
      { openPostModal && (
        <Overlay>
          <CreatePostModal>
            <Form currentPostId={currentPostId} setCurrentPostId={setCurrentPostId} />
          </CreatePostModal>
        </Overlay>
      )}
    </Main>
  )
}

export default Home;