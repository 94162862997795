import React from 'react';
import styled from 'styled-components';
import moment from 'moment'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deletePost } from '../../../actions/posts';
import LikesDislikes from '../../LikesDislikes/LikesDislikes';

const Main = styled.div`
  background: white;
  display: flex;
  padding: 20px;
  flex: 1;
  border-right: 1px solid #80808036;
  border-bottom: 1px solid #80808036;
  font-size: 14px;
  line-height: 20px;
  flex-basis: 300px;
`
const PostContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 1;
  font-size: 12px;
`
const PostDetails = styled.div`
  display: flex;
  align-items: center;
`
const PostCreator = styled.div`
  margin-right: 10px;
  font-weight: bold;
`
const PostDate = styled.div`
  color: #2c2b2beb;
  font-size: 10px;
  margin-right: 10px;
`
const UpdateButton = styled.button`
  color: #7d7b7b;
  border: none;
  background: none;
  font-weight: bold;
  letter-spacing: 0.3px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`

const PostText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const PostActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`
const PostTags = styled.div`
  color: #2c2b2beb;
  font-size: 10px;
  margin-right: 10px;
`
const DeleteButton = styled.button`
  color: white;
  background: red;
  padding: 7px 10px;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  &:hover {
    background: #d70000;
    color: white;
    transition: 0.5s;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

const Post = ({ post, setCurrentPostId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('profile'));

  const handleDelete = () => {
    dispatch(deletePost(post._id));
  }

  const isCreator = () => {
    return user?.result?.sub === post.creator || user?.result?._id === post.creator;
  }

  const getUserId = () => {
    return user?.result?.name ? (user?.result?._id || user?.result?.sub) : '';
  }

  const handlePostUpdate = () => {
    setCurrentPostId(post._id);
    dispatch({ type: 'OPENPOSTMODAL' });
  }

  const openPost = () => {
    history.push(`/posts/${post._id}`);
  }

  return (
    <Main>
      <PostContent>
        <button type="button" onClick={openPost}>Open Post</button>
        <PostDetails>
          <PostCreator>{post.name}</PostCreator>
          <PostDate>{moment(post.createdAt).fromNow()}</PostDate>
          { isCreator() && <UpdateButton onClick={handlePostUpdate}>...</UpdateButton> }
        </PostDetails>
        <h2>{post.title}</h2>
        <PostText>{post.message}</PostText>
        <PostActions>
          <PostTags>{post.tags}</PostTags>
          { isCreator() && <DeleteButton onClick={handleDelete}>DELETE</DeleteButton> }
        </PostActions>
      </PostContent>
      <ImageContainer>
        <Image src={post.selectedFile} alt={post.title} />
      </ImageContainer>
      <LikesDislikes post postId={post._id} userId={getUserId()} />
    </Main>
  )
}

export default Post;