import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FileBase from 'react-file-base64';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, updatePost } from '../../actions/posts';

const FormContainer = styled.div`
  flex: 0.4;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 18px;
`

const PermissionWarning = styled.div`
  padding: 20px;
  padding-bottom: 40px;
`

const CloseModalButton = styled.button`
  color: grey;
  font-size: 10px;
  line-height: 16px;
  border-radius: 50%;
  border: 1px solid grey;
  background: lightgrey;
  align-self: flex-end;
  cursor: pointer;

  &:hover {
    background: darkgrey;
    transition: 0.5s;
    color: white;
  }
`

const FormFields = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 15px;
  border-radius: 10px;
`

const TextLabel = styled.label`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TextField = styled.input`
  width: 70%;
  max-width: 70%;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
`
const Button = styled.button`
  width: 80px;
  max-width: 80px;
  padding: 2px;
  margin-right: 10px;
  line-height: 22px;
  
  cursor: pointer;
  background: white;
  border-radius: 10px;
  border: 1px solid grey;
  box-shadow: 2px 1px 8px rgb(186, 186, 186);
  
  &:hover {
    background: rgb(228, 228, 228);
    transition: 0.5s;
  }
`

const FormActions = styled.div`
  margin-top: 20px;
  display: flex;
`

const Form = ({ currentPostId, setCurrentPostId }) => {
  const dispatch = useDispatch();
  const post = useSelector((state) => currentPostId ? state.posts.posts.find((p) => p._id === currentPostId) : null);
  const [postData, setPostData] = useState({ message: '', title: '', tags: '', selectedFile: '' });
  const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);

  const clear = () => {
    setCurrentPostId(null);
    setPostData({ message: '', title: '', tags: '', selectedFile: '' })
  }

  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    setPostData({
      ...postData,
      [field]: value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Not to get refresh in browser

    if (currentPostId) {
      dispatch(updatePost(currentPostId, { ...postData, name: user?.result?.name }));
    } else {
      dispatch(createPost({ ...postData, name: user?.result?.name }));
    }
    clear();
    dispatch({ type: 'CLOSEPOSTMODAL' });
  }

  const handleModal = (e) => {
    e.preventDefault();
    dispatch({ type: 'CLOSEPOSTMODAL' });
    clear();
  }

  if (!user?.result?.name) {
    return (
      <FormContainer>
        <CloseModalButton onClick={handleModal}>X</CloseModalButton>
        <PermissionWarning>
          Please sign in to create a post!
        </PermissionWarning>
      </FormContainer>
    )
  }

  return (
    <FormContainer>
      <CloseModalButton onClick={handleModal}>X</CloseModalButton>
      <h2>{currentPostId ? 'Editing' : 'Create'} a Post</h2>
      <FormFields autoComplete='off' noValidate onSubmit={handleSubmit}>
        <TextLabel>
          <div>Title</div>
          <TextField name="title" type="text" value={postData.title} onChange={(e) => handleChange(e)} />
        </TextLabel>
        <TextLabel>
          <div>Content</div>
          <TextField name="message" type="text" value={postData.message} onChange={(e) => handleChange(e)} />
        </TextLabel>
        <TextLabel>
          <div>Tags</div>
          <TextField name="tags" type="text" value={postData.tags} onChange={(e) => handleChange(e)} />
        </TextLabel>
        <div>
          <FileBase
            type="file"
            multiple={false}
            onDone={({base64}) => setPostData({ ...postData, selectedFile: base64})} 
          />
        </div>
        <FormActions>
          <Button type="submit">Submit</Button>
          <Button type="button" onClick={clear}>Clear</Button>
        </FormActions>
      </FormFields>
    </FormContainer>
  )
}

export default Form;