import { FETCH_ALL, CREATE, UPDATE, DELETE, FETCH_POST, START_LOADING, END_LOADING, COMMENT, DELETE_COMMENT, FETCH_COMMENTS, CREATE_REPLY, DELETE_REPLY } from '../constants/actionTypes';
import * as api from '../api';

export const getPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts();
    const action = { type: FETCH_ALL, payload:  data };
    dispatch(action);
  } catch (error) {
    console.log(error.message);
  }
}

export const createPost = (newPost) => async (dispatch) => {
  try {
    const { data } = await api.createPost(newPost);
    const action = { type: CREATE, payload: data };
    dispatch(action);
  } catch (error) {
    console.log(error.message);
  }

}

export const updatePost = (id, updatedPost) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, updatedPost);
    const action = { type: UPDATE, payload: data };
    dispatch(action);
  } catch (error) {
    console.log(error.message);
  }
}

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id);
    const action = { type: DELETE, payload: id }
    dispatch(action);
  } catch (error) {
    console.log(error);
  }
}

export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchPost(id);
    const action = { type: FETCH_POST, payload: data };
    dispatch(action);
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
  }
}

export const getPostComments = (postId) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING_COMMENTS' });
    const { data } = await api.getPostComments(postId);
    const action = { type: FETCH_COMMENTS, payload: data };
    dispatch(action);
    dispatch({ type: 'END_LOADING_COMMENTS' });
  } catch (error) {
    console.log(error.message);
  }
}

export const createPostComment = (postId, newComment) => async (dispatch) => {
  try {
    const { data } = await api.createPostComment(postId, newComment);
    const action = { type: COMMENT, payload: data } // new post comment -- update post comments array as well?
    dispatch(action);

  } catch (error) {
    console.log(error.message);
  }
}

export const deletePostComment = (postId, commentId) => async (dispatch) => {
  try {
    await api.deletePostcomment(postId, commentId);
    const action = { type: DELETE_COMMENT, payload: { postId, commentId } };
    dispatch(action);

  } catch (error) {
    console.log(error.message);
  }
}

export const createCommentReply = (postId, commentId, replyData) => async (dispatch) => {
  try {
    const { data } = await api.createCommentReply(postId, commentId, replyData);
    const action = { type: CREATE_REPLY, payload: { commentId, reply: data } };
    dispatch(action);

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteCommentReply = (postId, commentId, replyId) => async (dispatch) => {
  try {
    await api.deleteCommentReply(postId, commentId, replyId);
    const action = { type: DELETE_REPLY, payload: { relatedCommentId: commentId, deletedReplyId: replyId } };
    dispatch(action);
  } catch (error) {
    console.log(error.message);
  }
};